import { StreamMessage } from "stream-chat-react";
import { StreamChatType } from "../types";

export const formatDate = (date: Date) =>
  new Intl.DateTimeFormat("es-ES", {
    dateStyle: "full",
    timeStyle: "short",
    timeZone: "Europe/Madrid",
  }).format(date);

export const formatActualDate = (date: Date) => {
  const actualDate = formatDate(date).split(',')
  const [day, gameDate, hour] = actualDate
  const [gameDay, gameMonth] = gameDate.split(' de ')

  return `${capitalizeWord(day)} ${gameDay} de ${gameMonth} a las ${hour}h`
}

const capitalizeWord = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

export const defineUserIdByLocation = (location: any) => {
  if (location.search.includes('userId')) {
    const userId = location.search.split('=')[1]
    return userId
  }

  return null
}

export const formatNumber = (num: number) => {
  return new Intl.NumberFormat("es-ES").format(num);
}

export const sortByPinnedDate = (messages: StreamMessage<StreamChatType>[]) => {
  return messages.sort((a, b) => {
    const aPinned = a.pinned_at ? new Date(a.pinned_at).getTime() : 0;
    const bPinned = b.pinned_at ? new Date(b.pinned_at).getTime() : 0;

    return bPinned - aPinned;
  })
}

export function checkAndReload(targetDate: Date) {
  // Set the interval to check the current date against the target date
  const intervalId = setInterval(function() {
    const currentDate = new Date();

    // Check if the current date is greater than the target date
    if (currentDate >= targetDate) {
      // If true, reload the page
      location.reload();

      // Clear the interval to stop further checks
      clearInterval(intervalId);
    }
  }, 30000);
}


export const formatTime = (date: Date) => {
  const time = new Intl.DateTimeFormat("es-ES", {
    timeStyle: "short",
    timeZone: "Europe/Madrid",
  }).format(date);

  return time
}