import axios from "axios";
import { AccessTokenType, type TokenType } from "../types";
import { ACCESS_TOKEN } from "../constants";

export const getToken = async (uri: string, access_token?: string | null): Promise<TokenType> => {
    return await axios.get(uri, {
        headers: {
            'token': `Bearer ${access_token}`
        }
    })
    .then(response => response.data)
    .catch(err => console.log(err))
}

export const getAccessToken = async (uri:string, code: string | null): Promise<AccessTokenType> => {
    return await axios.get(uri, {
        headers: {
            'code': code
        }
    })
    .then(response => response.data)
}

export const refreshAccessToken = async (uri:string, refresh_token: string | null): Promise<AccessTokenType> => {
    return await axios.get(uri, {
        headers: {
            'Refresh-Token': refresh_token
        }
    })
    .then(response => response.data)
}

export const getAccessTokenFromLocalStorage = (): string | null => {
    return localStorage.getItem(ACCESS_TOKEN)
}