import {Helmet} from "react-helmet"

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

export const Head = () => (
    <Helmet>
        {/* Google tag (gtag.js) */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`}></script>
        <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${TRACKING_ID}');`}
        </script>
    </Helmet>
)