import {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
} from "react";

import { Team } from "../types";
import { getGames } from "../services/Game";
import { BACKEND_API } from "../constants";

const noop = () => null;

export type GameControllerContext = {
    gameId: number;
    setGameId: Dispatch<SetStateAction<number>>;
    date: Date;
    setDate: Dispatch<SetStateAction<Date>>;
    teams: Team[];
    setTeams: Dispatch<SetStateAction<Team[]>>;
}

const GameController = createContext<GameControllerContext>({
    gameId: 0,
    setGameId: noop,
    date: new Date(),
    setDate: noop,
    teams: [],
    setTeams: noop,
});

export const GameControlProvider = ({children}: {children: ReactNode}) => {
    const [date, setDate] = useState<Date>(new Date());
    const [gameId, setGameId] = useState<number>(0);
    const [teams, setTeams] = useState<Team[]>([]);

    return <GameController.Provider value={{
        gameId,
        setGameId,
        date,
        setDate,
        teams,
        setTeams,
    }}>{children}</GameController.Provider>;
  }

export const useGameController = (team: string | undefined) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {date, setDate, teams, setTeams, gameId, setGameId} = useContext(GameController);

    useEffect(() => {
        const fetchGames = async () => {
            setLoading(true);
            await getGames(`${BACKEND_API}/games/nearest-game/${team}`)
            .then(response => {
                const {id, date, teams} = response

                setGameId(id)
                setTeams(teams)
                const [specificDate, hour] = date.split(" ")
                const newDate = new Date(`${specificDate.replaceAll('-', '/')}, ${hour}`)
                setDate(newDate)
            })
            .finally(() => setLoading(false))
        }

        if (teams.length === 0) fetchGames()
    }, [teams])

    return {
        gameId,
        date,
        teams,
        loading
    }
}