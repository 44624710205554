import { Team } from "../../types";

type GamingChatHeaderProps = {
  teams: Team[];
}

export const GamingChatHeader = ({ teams }: GamingChatHeaderProps) => {
  return (
    <div className='channel-header'>
        <h3>{teams[0]?.name}</h3>
          {
            teams?.map(({name, logo}) => (
              <div key={name}>
                <img src={logo} alt={name} />
              </div>
            ))
          }
        <h3>{teams[1]?.name}</h3>
    </div>
  );
};
