export const ActionBanned = () => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 10</title>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-381, -547)">
        <g id="Group-10" transform="translate(381, 547)">
          <circle id="Oval" cx="13" cy="13" r="13"></circle>
          <g
            id="ban-user"
            transform="translate(5, 5)"
            fill="#3C3C3C"
            fillRule="nonzero"
          >
            <path
              d="M2.77254321,3.16049383 C2.77254321,1.41500128 4.18754449,0 5.93303704,0 C7.67852958,0 9.09353086,1.41500128 9.09353086,3.16049383 C9.09353086,4.90598637 7.67852958,6.32098765 5.93303704,6.32098765 C4.18754449,6.32098765 2.77254321,4.90598637 2.77254321,3.16049383 L2.77254321,3.16049383 Z M7.30864198,12.2469136 C7.3062963,10.7928358 7.94869924,9.41253756 9.06271605,8.47802469 C9.10654642,8.4336966 9.13195535,8.3744091 9.13382716,8.31209877 C9.13382716,8.15407407 8.93471605,8.08533333 8.89679012,8.07506173 C8.44286614,7.95645718 7.9753249,7.89801453 7.50617284,7.90123457 L4.34567901,7.90123457 C1.13777778,7.90123457 0,10.2479012 0,12.262716 C0,14.0641975 0.956049383,15.0123673 2.7654321,15.0123673 L7.73530864,15.0123673 C7.86810306,15.0141051 7.9772913,14.9080971 7.97945679,14.7753086 C7.98162163,14.7390923 7.9736892,14.7029861 7.95654321,14.6710123 C7.53304016,13.9332387 7.30969418,13.0975978 7.30864198,12.2469136 L7.30864198,12.2469136 Z M16,12.2469136 C16,14.319686 14.319686,16 12.2469136,16 C10.1741412,16 8.49382716,14.319686 8.49382716,12.2469136 C8.49382716,10.1741412 10.1741412,8.49382716 12.2469136,8.49382716 C14.3186031,8.49643909 15.9973881,10.175224 16,12.2469136 L16,12.2469136 Z M10.0677531,13.5901235 L13.5901235,10.0677531 C12.5809178,9.4329608 11.2668053,9.58071084 10.4237581,10.4237581 C9.58071084,11.2668053 9.4329608,12.5809178 10.0677531,13.5901235 L10.0677531,13.5901235 Z M14.8148148,12.2469136 C14.8135306,11.7716142 14.6788404,11.3062212 14.4260741,10.9037037 L10.9037037,14.4260741 C11.6932667,14.9209814 12.6896793,14.9466788 13.503703,14.4931278 C14.3177267,14.0395768 14.8201915,13.1787473 14.8148148,12.2469136 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
