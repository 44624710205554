export const SendIcon = () => (
  <svg
    width="24.7058824px"
    height="21.1764706px"
    viewBox="0 0 24.7058824 21.1764706"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>ic_arrow_forward</title>
    <defs>
      <polygon
        id="path-1-send-icon"
        points="0.0117647059 21.1764706 24.7058824 10.5882353 0.0117647059 0 0 8.23529412 17.6470588 10.5882353 0 12.9411765"
      ></polygon>
    </defs>
    <g id="V2-send-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="laliga-livechat--partido-activo-2-light"
        transform="translate(-358.8918, -798.4118)"
      >
        <g id="Rectangle" transform="translate(25.4212, 765)">
          <g id="Group" transform="translate(25, 20)">
            <g id="Group-3" transform="translate(292, 0)">
              <g id="ic_arrow_forward" transform="translate(16.4706, 13.4118)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1-send-icon"></use>
                </mask>
                <use id="Mask" fill="#FF4B44" xlinkHref="#path-1-send-icon"></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
