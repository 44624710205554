export const updateMuteMessage = (message: string | undefined, userId: string | undefined, splitChar: string) => {
    if (message && userId) {
        const splitedMessage = message.split(splitChar);

        return splitedMessage[0] + " " + userId;
    }

    return message;
}

export const isAction = (action: 'mute' | 'unmute' | 'ban' | 'unban', message: string | undefined) => message && message.includes(action)

export const generateMessage = (action: 'mute' | 'unmute' | 'ban' | 'unban', userId: string | undefined, message: string | undefined) => {
    if ((action === 'mute' || action === 'unmute')) {
        const actionDescription = action === 'mute' ? 'muteado' : 'desmuteado';
        return `Usuario ${userId} ${actionDescription}`
    }

    if ((action === 'ban' || action === 'unban')) {
        const actionDescription = action === 'ban' ? 'baneado' : 'desbaneado';

        const customMessage = message?.split(' ').splice(6).join(' ');
        return `Usuario ${userId} ${actionDescription} por ${customMessage}`
    }

    return message;
}