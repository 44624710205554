import { TEAMS } from "../constants"
import { useGameController } from "../context/GameContext"

export const useInfoDay = (team: string | undefined) => {
    const { date, teams, loading, gameId } = useGameController(team)

    const filterByAttribute = (attribute: 'name' | 'logo') =>  teams.map(item => item[attribute])

    const handleContentByDate = (date: Date) => date > new Date()

    const validateTeam = () => team && TEAMS.includes(team)

    const teamsToString = (team: string | undefined) => team + '-' + gameId

    return {
        date,
        teams,
        loading,
        filterByAttribute,
        handleContentByDate,
        validateTeam,
        teamsToString,
    }
}
