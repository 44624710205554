import React from 'react';

const VIPIcon = () => (
  <svg className='user-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='16' height='16' rx='2' fill='#DD8500' />
    <path
      d='M7.61958 1.79582C7.73932 1.42729 8.26068 1.4273 8.38042 1.79582L9.70631 5.87647C9.75986 6.04128 9.91344 6.15286 10.0867 6.15286H14.3774C14.7649 6.15286 14.926 6.64871 14.6125 6.87647L11.1413 9.39845C11.0011 9.50031 10.9424 9.68086 10.996 9.84566L12.3219 13.9263C12.4416 14.2948 12.0198 14.6013 11.7063 14.3735L8.23511 11.8515C8.09492 11.7497 7.90508 11.7497 7.76489 11.8515L4.29368 14.3735C3.98019 14.6013 3.5584 14.2948 3.67814 13.9263L5.00402 9.84567C5.05757 9.68086 4.99891 9.50031 4.85872 9.39845L1.38751 6.87647C1.07402 6.64871 1.23513 6.15286 1.62262 6.15286H5.91327C6.08656 6.15286 6.24014 6.04128 6.29369 5.87647L7.61958 1.79582Z'
      fill='white'
    />
  </svg>
);

export default VIPIcon;
