import { Fragment, MouseEventHandler, ReactNode, useEffect, useRef } from "react"

type BannerProps = {
    title: string,
    handleClose: MouseEventHandler<HTMLButtonElement>,
    children: ReactNode
}

export const Banner = ({title, handleClose, children}: BannerProps) => {
    const bannerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (bannerRef.current && !bannerRef.current.contains(event.target as Node)) {
                handleClose(event as any);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClose]);

    return (
        <Fragment>
            <div className="banner-background"></div>
            <section className='banner' ref={bannerRef}>
                <header>
                    <h3>{title}</h3>
                    <button onClick={handleClose}>
                        <img src='/icon_close.svg' alt='Cerrar' />
                    </button>
                </header>
                <div className="banner__body">
                    {children}
                </div>
            </section>
        </Fragment>
    )
}