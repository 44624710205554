const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

const useAnalyticsEventTracker = () => {
  const eventTracker = (action: string, label: string, user_id: string, event_time?: string) => {
    const config = {
      label: label,
      user_id: user_id,
      send_to: TRACKING_ID
    }

    if (event_time) {
      Object.assign(config, { event_time: event_time })
    }

    // @ts-ignore
    gtag('event', action, config);
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;