import { StreamChat } from 'stream-chat';

import { ActionRemove } from '../../assets/icons/ActionRemove';
import { ActionBanned } from '../../assets/icons/ActionBanned';
import { StreamChatType } from '../../types';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEvents';

type PopupConfirmationProps = {
    type: 'remove' | 'ban' | 'unban',
    tooglePopup: (isOpen: boolean) => void,
    messageId: string | null,
    client: StreamChat<StreamChatType>,
    channel: any,
    userId: string
}

const INFORMATION_POPUP = {
    'remove': {
        'icon': <ActionRemove />,
        'title': '¿Estás seguro de que quieres eliminar este mensaje?',
        'button': 'Si, eliminar'
    },
    'ban': {
        'icon': <ActionBanned />,
        'title': '¿Estás seguro de que quieres banear al usuario',
        'button': 'Si, bloquear'
    },
    'unban': {
        'icon': <ActionBanned />,
        'title': '¿Estás seguro de que quieres desbloquear al usuario',
        'button': 'Si, desbloquear'
    }
}

export const PopupConfirmation = ({type, tooglePopup, messageId, client, channel, userId}: PopupConfirmationProps) => {
    // hook google analytics event
	const gaEvent = useAnalyticsEventTracker();

    const handleCancel = (isOpen: boolean) => () => tooglePopup(isOpen)

    const handleConfirm = async () => {
        if (type === 'remove') {
            messageId && await client.deleteMessage(messageId, true);
            gaEvent('message_deleted', 'Deleted message', userId);
        } else if (type === 'ban') {
            await channel.banUser(messageId ?? '', {
                timeout: 240,
                reason: 'Banned for four hour',
            });
            gaEvent('user_ban', 'Banned user', userId);
        } else if (type === 'unban') {
            await channel.unbanUser(messageId ?? '');
        }
        tooglePopup(false);
    }
    const information = INFORMATION_POPUP[type]
    return (
        <div className='popup-confirmation'>
            <article>
                <header>{information.icon}</header>
                <div>{information.title}</div>
                <footer>
                    <button onClick={handleConfirm}>{information.button}</button>
                    <button onClick={handleCancel(false)}>
                        Cancelar
                    </button>
                </footer>
            </article>
        </div>
    )
}