import { Fragment } from "react"

import { formatActualDate } from "../../utils";
import { Team } from "../../types"

type GameInformationType = {
    teams: Team[];
    date: Date;
    filterByAttribute: (attribute: 'name' | 'logo') => string[];
    activeText?: boolean
}

export const GameInformation = ({ teams, date, filterByAttribute, activeText = false}: GameInformationType) => (
    <Fragment>
        <header>
            {
            teams?.map(({name, logo}: Team) => (
                <img key={name} src={logo} alt={name} />
            ))
            }
        </header>
        <section>
            <h2>{`${!activeText ? 'Comenta con nosotros el partido del' : ''} ${filterByAttribute('name').join(" vs ")}`}</h2>
            <p>{formatActualDate(date)}</p>
        </section>
    </Fragment>
)