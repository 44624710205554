export const Storage = {
    get: (key: string): string | null => {
        return localStorage.getItem(key) ?? null
    },
    set: (key: string, value: string): void => {
        localStorage.setItem(key, value)
    },
    remove: (key: string): void => {
        localStorage.removeItem(key)
    }
}