import axios from "axios";
import { useEffect, useState } from "react";

import { Polls } from "../types";
import { Storage } from "../services/storage";
import { USER_VOTES } from "../constants";

type VoteRequest = {
  optionSelected: number;
  pollId: number;
  access_token?: string | null;
  userId: string;
};

const URI_POLL_RECOVER = `${process.env.REACT_APP_API_URL}/polls`;

export const usePoll = () => {
  const [optionSelected, setOptionSelected] = useState<number>(-1);

  const handleVote = async ({
    pollId,
    optionSelected,
    access_token,
    userId,
  }: VoteRequest): Promise<Polls> => {
    return await axios
      .put(
        `${URI_POLL_RECOVER}/${pollId}/vote`,
        {
          option_id: optionSelected,
          user_uuid: userId,
        },
        {
          headers: {
            token: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => response.data)
      .catch((err) => console.log(err));
  };

  const getActivePoll = async ({
    channelId,
    access_token,
  }: {
    channelId: string;
    access_token?: string | null;
  }): Promise<Polls> => {
    return await axios
      .get(`${URI_POLL_RECOVER}/${channelId}`, {
        headers: {
          token: `Bearer ${access_token}`,
        },
      })
      .then((response) => response.data[0])
      .catch((err) => console.log(err));
  };

  const toggleWinner = (poll: Polls | null) => {
    if (!poll) return [];
    const maxVote = Math.max(...poll.options.map((option) => option.percentage));

    const maxVotes = poll.options.filter(vote => vote.percentage === maxVote).map(vote => vote.order);

    return maxVotes;
  }

  const determinateOptionMaxWidth = () => {
    // Select all the options
    const options: NodeListOf<HTMLElement> =
      document.querySelectorAll("#poll-options > li");

    // Determine the maximum width
    let maxWidth = 0;
    options.forEach((option) => {
      if (option.offsetWidth > maxWidth) {
        maxWidth = option.offsetWidth + 10;
      }
    });

    // Set the width of all options to the maximum width
    options.forEach((option) => {
      option.style.minWidth = `${maxWidth}px`;
    });
  };

  const validateOptionSelected = (option: number) => optionSelected === option;

  useEffect(() => {
    let localOptionSelected = Storage.get(USER_VOTES)
    if (localOptionSelected) {
      localOptionSelected = JSON.stringify(localOptionSelected)
    }
  }, [])

  return {
    getActivePoll,
    handleVote,
    toggleWinner,
    determinateOptionMaxWidth,
    optionSelected,
    setOptionSelected,
    validateOptionSelected
  };
};
