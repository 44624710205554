import { Navigate, Route, Routes } from "react-router-dom";

import { GamingChat } from "./components/Chat";
import { Home } from "./components/Home/Index";
import { NoMatch } from "./components/NoMatch";

import { OneTrust } from "./components/OneTrust";
import { GameControlProvider } from "./context/GameContext";
import { LayoutControlProvider } from "./context/LayoutController";
import { Head } from "./components/Head/Index";

const App = () => {
  return (
    <>
      <Head />
      <main>
        <OneTrust />
        <LayoutControlProvider>
          <GameControlProvider>
            <Routes>
              <Route path="/" element={<NoMatch />} />
              <Route path=":team" element={<Home />} />
              <Route path=":team/stream" element={<GamingChat />} />
              <Route path="/404" element={<NoMatch />} />
              <Route path="*" element={<Navigate to={`/404`} replace />} />
            </Routes>
          </GameControlProvider>
        </LayoutControlProvider>
      </main>
    </>
  );
};

export default App;
