import { useEffect, useState } from "react";
import {
  DefaultGenerics,
  ExtendableGenerics,
  OwnUserResponse,
  StreamChat,
  UserResponse,
} from "stream-chat";
import useToken from "./useToken";
import { BACKEND_API } from "../constants";
import { getAccessToken, getAccessTokenFromLocalStorage, refreshAccessToken } from "../services/Token";
import { Storage } from "../services/storage";
import { getColor, getRandomUserRole } from "../assets/data";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import { getProfileInfo, updateProfileUser } from "../services/User";
import { UserInformationType } from "../types";
import useAnalyticsEventTracker from "./useAnalyticsEvents";

/**
 * A hook which handles the process of connecting/disconnecting a user
 * to the Stream Chat backend.
 *
 * @param apiKey the Stream app API key to use.
 * @param userToConnect the user information.
 * @param userTokenOrProvider the user's token.
 */
export const useConnectUser = <
  SCG extends ExtendableGenerics = DefaultGenerics
>(
  apiKey: string,
  queryCode: string | null,
  team: string | undefined
) => {
  const gaEvent = useAnalyticsEventTracker();
  const [chatClient, setChatClient] = useState<StreamChat<SCG> | null>(null);
  const [userInformation, setUserInformation] = useState<UserInformationType>({ username: '', thumbnail: '' })
  const { fetchTokenByUser } = useToken()
  useEffect(() => {
    const client = new StreamChat<SCG>(apiKey, {
      enableInsights: true,
      enableWSFallback: true,
    });

    const getInformation = async (access_token: string) => {
      return getProfileInfo(`${BACKEND_API}/oauth/me`, access_token)
      .then(profileInfo => {
        const { alias, avatar_thumbnail_url, fan_number, id_user, new_user, registration_date } = profileInfo

        if (new_user) {
          // Track when user is new in `La Liga`
          gaEvent('new_register', 'New User', id_user, registration_date);
        }

        const idUserLastSevenChar = id_user?.slice(-7)
        let username = `Fan #${idUserLastSevenChar}`

        if (alias) {
          username = alias
        } else if (fan_number) {
          username = `Fan #${fan_number}`
        }
        setUserInformation({
          username: username,
          thumbnail: avatar_thumbnail_url
        })

        return {id_user, username, avatar_thumbnail_url, access_token}
      })
      .then(({id_user, username, avatar_thumbnail_url, access_token}) => {

        return fetchTokenByUser(`${BACKEND_API}/stream-provider/user/${id_user}/token`, access_token)
        .then(token => {
          return updateProfileUser(`${BACKEND_API}/stream-provider/user`, {
            id_user: id_user ?? '',
            name: username,
            avatar_url: avatar_thumbnail_url ?? ''
          })
          .then(() => {
            const userToConnect: OwnUserResponse<SCG> | UserResponse<SCG> = {
              id: id_user ?? '',
              color: getColor(),
              userRole: getRandomUserRole(),
              language: 'es',
            };
            return client
            .connectUser(userToConnect, token)
            .then(() => {
              if (!didUserConnectInterrupt) {
                setChatClient(client);
              }
            });
           })
        })
      })
    }

    const toogleLaLigaOauth = async () => {
      const access_token = getAccessTokenFromLocalStorage()
      if (access_token) {
        return getInformation(access_token)
      } else {
        return getAccessToken(`${BACKEND_API}/oauth/token`, queryCode)
        .then(response => {
          saveTokens(response)
          return response.access_token
        })
        .then(access_token => {
          return getInformation(access_token)
        })
      }
    }

    const saveTokens = async ({access_token, refresh_token}: {access_token: string, refresh_token: string}) => {
      Storage.set(ACCESS_TOKEN, access_token)
      Storage.set(REFRESH_TOKEN, refresh_token)
    }

    // Under some circumstances, a "connectUser" operation might be interrupted
    // (fast user switching, react strict-mode in dev). With this flag, we control
    // whether a "disconnectUser" operation has been requested before we
    // provide a new StreamChat instance to the consumers of this hook.
    let didUserConnectInterrupt = false;
    const connectUser = toogleLaLigaOauth()
    .catch(async (error) => {
      if (error.response.status === 401) {
        // Call refresh token function here
        const refresh_token = Storage.get(REFRESH_TOKEN)
        const newAccessToken = await refreshAccessToken(`${BACKEND_API}/oauth/refresh-token`, refresh_token)
        .catch(() => {
            Storage.remove(ACCESS_TOKEN)
            Storage.remove(REFRESH_TOKEN)
            window.location.href = `/${team}`
            return;
        })
        if (newAccessToken) {
          saveTokens(newAccessToken)
          return getInformation(newAccessToken.access_token)
        }
      } else {
        window.location.href = `/${team}`
      }
    })

    return () => {
      didUserConnectInterrupt = true;
      // there might be a pending "connectUser" operation, wait for it to finish
      // before executing the "disconnectUser" in order to prevent race-conditions.
      connectUser.then(() => {
        setChatClient(null);
        client.disconnectUser().catch((e) => {
          console.error(`Failed to disconnect user`, e);
        });
      });
    };
  }, [apiKey]);

  return {
    chatClient,
    userInformation,
  };
};
