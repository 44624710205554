import React from 'react';

const StreamerIcon = () => (
  <svg className='user-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='16' height='16' rx='2' fill='#DA2752' />
    <path
      d='M7.7191 1.74906C7.81643 1.48951 8.18357 1.48951 8.2809 1.74906L10.8364 8.56376C10.913 8.76806 11.1752 8.82484 11.3294 8.67056L14.3953 5.60474C14.5958 5.40422 14.9369 5.56815 14.9056 5.85L14.0297 13.7331C14.0128 13.8851 13.8844 14 13.7315 14H2.26851C2.11565 14 1.98723 13.8851 1.97035 13.7331L1.09444 5.85C1.06313 5.56815 1.40422 5.40422 1.60474 5.60474L4.67056 8.67056C4.82484 8.82484 5.08698 8.76806 5.16359 8.56376L7.7191 1.74906Z'
      fill='white'
    />
  </svg>
);

export default StreamerIcon;
