import { Channel as ChannelT } from "stream-chat"
import { useEffect, useState } from "react";

import { PollEvent, Polls, StreamChatType } from "../types";


export const useChannelWatch = (channel: ChannelT<StreamChatType>) => {
    const [watchers, setWatchers] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [activePoll, setActivePoll] = useState(false);
    const [poll, setPoll] = useState<Polls | null>(null);
    const [closePoll, setClosePoll] = useState(false);
    const [stayFocus, setStayFocus] = useState(false);

    useEffect(() => {
        setWatchers(channel.state.watcher_count ?? 0)
    }, []);

    const activeShowPoll = (data: Polls) => {
        setPoll(data)
        setTimeout(() => {
            setIsOpen(true)
            setActivePoll(true)
        }, 1000)
    }

    useEffect(() => {
        channel.watch().then(() => {
            channel.on("all", event => {
                console.log({event})
            })
            // Escuchar cuando un usuario comienza a observar el canal
            channel.on('user.watching.start', event => {
                setWatchers(event.watcher_count ?? 0)
            });

            // Escuchar cuando un usuario deja de observar el canal
            channel.on('user.watching.stop', event => {
                setWatchers(event.watcher_count ?? 0)
            });

            channel.on('message.new', event => {
                const isTextareaFocus = document.activeElement instanceof HTMLTextAreaElement;
                if (channel.getClient().userID === event.user?.id || isTextareaFocus) {
                    setStayFocus(true);
                } else {
                    setStayFocus(false);
                }

                if (event.message?.silent) {
                    setStayFocus(false);
                    setClosePoll(false);
                    const data = JSON.parse(event.message?.text ?? '') as PollEvent
                    if (data.event === 'open') {
                        const actualPoll = JSON.parse(data.poll) as Polls
                        activeShowPoll(actualPoll)
                    }
                    if (data.event === 'close') {
                        const actualPoll = JSON.parse(data.poll) as Polls
                        activeShowPoll(actualPoll)
                        setClosePoll(true);
                    }
                }
            });

          }).catch(error => {
            console.error('Error observando el canal:', error);
        });

        return () => {
            channel.stopWatching();
        }
    }, [channel]);


    return {
        watchers,
        isOpen,
        setIsOpen,
        activePoll,
        setActivePoll,
        poll,
        setPoll,
        closePoll,
        activeShowPoll,
        stayFocus,
    }
}