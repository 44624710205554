import {useEffect, useState} from 'react';
import {Channel, Chat} from 'stream-chat-react';
import { useNavigate, useParams } from 'react-router-dom';
import {Channel as ChannelT} from 'stream-chat';

import {GamingChatInner} from './GamingChatInner';
import {GamingChatNotification} from './GamingChatNotification';
import {GamingThreadHeader} from './GamingThreadHeader';

import {useChecklist} from '../../hooks/useChecklistTasks';

import {useConnectUser} from '../../hooks/useConnectUser';
import {useLayoutController} from '../../context/LayoutController';
import type {StreamChatType} from '../../types';
import {MessageTimestampController} from "../../context/MessageTimestampController";
import { ChatHeader } from './ChatHeader';
import { useInfoDay } from '../../hooks/useInfoDay';
import { ROLE_TYPES, RULES } from '../../constants';
import { detectFirstTime } from '../../services/detectIfFirstTime';
import { Banner } from './Banner';
import { checkAndReload, formatDate } from '../../utils';
import { generateMessage, isAction } from '../../services/Message';
import { t } from '../../locales/es';


const DEFAULT_LANGUAGE = 'es'
const apiKey = process.env.REACT_APP_STREAM_KEY;
const targetOrigin = process.env.REACT_APP_TARGET_ORIGIN;

const urlParams = new URLSearchParams(window.location.search);
const queryCode = urlParams.get('code')

export const GamingChat = () => {
	const navigate = useNavigate()
	const { team } = useParams()
	const [showRules, setShowRules] = useState<boolean>(false);

	const [channel, setChannel] = useState<ChannelT<StreamChatType> | null>(null);
	const {memberListVisible, popUpText, upgradePanelVisible, chatVisible, setModerator} = useLayoutController();
	const { chatClient, userInformation } = useConnectUser<StreamChatType>(apiKey!, queryCode, team);
	const { teamsToString, validateTeam, date } = useInfoDay(team)

	const name = teamsToString(team)
	const channelId = `laliga-${name}`

	if (!validateTeam()) navigate(`/404`)

	useChecklist({chatClient, targetOrigin});

	const handleShowRules = () => {
		setShowRules(true)
	}

	const handleCloseRules = () => {
		setShowRules(false)
	}

	useEffect(() => {
		if (!chatClient) return;
		const loadChat = async () => {

			const channel = chatClient.channel(
				'livestream',
				channelId,
				{name: `La Liga - ${name}`});
			await channel.watch();

			await channel.addMembers([chatClient.user?.id ?? '']);
			setChannel(channel);
			ChannelT<StreamChatType>
			if (ROLE_TYPES.includes(chatClient.user?.role ?? '')) setModerator(true);
			else setModerator(false);
		};

		loadChat();

		if (detectFirstTime()) {
			handleShowRules()
		}
	}, [chatClient]);

	const currentDate = new Date()

	if (formatDate(currentDate) < formatDate(date)) checkAndReload(date)

	if (!chatClient) return null;

	const CustomSystemMessage = (props: any) => {
		const { message } = props;

		const { created_at = '', text, type, mentioned_users } = message;
		const date = created_at.toString();

		let action: 'mute' | 'unmute' | 'ban' | 'unban' = 'mute'

		if (isAction('mute', text)) action = 'mute'
		if (isAction('unmute', text)) action = 'unmute'
		if (isAction('ban', text)) action = 'ban'
		if (isAction('unban', text)) action = 'unban'

		const m = t(action, {user: mentioned_users[0]?.name, date: formatDate(new Date(date))})
		if (type === 'system') {
			return (
				<div className='custom-message__wrapper custom-system__message'>
					<span>{ m }</span>
				</div>
			);
		}

		return null;
	};

	return (
		<section
			className={`sidebar ${memberListVisible} ${chatVisible} ${upgradePanelVisible ? 'show-upgrade' : ''}`}
		>
			{
				!chatClient
				? <h1>No chat created</h1>
				: channel && (
					<>
						<ChatHeader
							handleShowRules={handleShowRules}
							userInformation={userInformation}
							team={team ?? ''}
							channel={channel}
							showRules={showRules}
						/>
						<div className='chat-container'>
							<Chat client={chatClient} defaultLanguage={DEFAULT_LANGUAGE}>
								<Channel channel={channel} ThreadHeader={GamingThreadHeader} MessageSystem={CustomSystemMessage}>
									<MessageTimestampController>
										<GamingChatInner
											channelId={channelId}
											team={team}
											client={chatClient}
											channel={channel}
											handleShowRules={handleShowRules}
										/>
									</MessageTimestampController>
								</Channel>
							</Chat>
							{popUpText && <GamingChatNotification text={popUpText}/>}

							{
								showRules && (
									<Banner title='Reglas del canal' handleClose={handleCloseRules}>
										{
											RULES.map((rule) => {
												return (
													<div className='banner-content' key={rule.label}>
														<img src='/icon_check.svg' alt='Reglas' />
														<p>
															<strong>{rule.label}: </strong>
															<span>{rule.text}</span>
														</p>
													</div>
												)
											})
										}
									</Banner>
								)
							}
						</div>
					</>
				)
			}
		</section>
	);
};


// get the date of game
// pass to function
// check if work
