export const ActionRemove = () => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 11</title>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-348, -547)">
        <g id="Group-11" transform="translate(348, 547)">
          <circle id="Oval-Copy" cx="13" cy="13" r="13"></circle>
          <g
            id="trash"
            transform="translate(7, 4)"
            fill="#3C3C3C"
            fillRule="nonzero"
          >
            <path
              d="M11.75,2 L9,2 L9,1.5 C9,0.6715625 8.3284375,0 7.5,0 L5.5,0 C4.6715625,0 4,0.6715625 4,1.5 L4,2 L1.25,2 C0.55965625,2 0,2.55965625 0,3.25 L0,4.25 C0,4.52615625 0.22384375,4.75 0.5,4.75 L12.5,4.75 C12.7761562,4.75 13,4.52615625 13,4.25 L13,3.25 C13,2.55965625 12.4403438,2 11.75,2 Z M5,1.5 C5,1.224375 5.224375,1 5.5,1 L7.5,1 C7.775625,1 8,1.224375 8,1.5 L8,2 L5,2 L5,1.5 Z M0.948875,5.75 C0.85965625,5.75 0.7885625,5.8245625 0.7928125,5.9136875 L1.2053125,14.57125 C1.2434375,15.3725 1.9015625,16 2.7034375,16 L10.2965625,16 C11.0984375,16 11.7565625,15.3725 11.7946875,14.57125 L12.2071875,5.9136875 C12.2114375,5.8245625 12.1403438,5.75 12.051125,5.75 L0.948875,5.75 Z M8.5,7 C8.5,6.72375 8.72375,6.5 9,6.5 C9.27625,6.5 9.5,6.72375 9.5,7 L9.5,13.5 C9.5,13.77625 9.27625,14 9,14 C8.72375,14 8.5,13.77625 8.5,13.5 L8.5,7 Z M6,7 C6,6.72375 6.22375,6.5 6.5,6.5 C6.77625,6.5 7,6.72375 7,7 L7,13.5 C7,13.77625 6.77625,14 6.5,14 C6.22375,14 6,13.77625 6,13.5 L6,7 Z M3.5,7 C3.5,6.72375 3.72375,6.5 4,6.5 C4.27625,6.5 4.5,6.72375 4.5,7 L4.5,13.5 C4.5,13.77625 4.27625,14 4,14 C3.72375,14 3.5,13.77625 3.5,13.5 L3.5,7 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
