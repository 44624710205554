import { useEffect, useRef, useState } from "react";
import { LocalUserVote, type Polls } from "../../types";
import { ProgressBar } from "../ProgressBar/Index";
import { usePoll } from "../../hooks/usePoll";
import { Storage } from "../../services/storage";
import { USER_VOTES } from "../../constants";
import { getAccessTokenFromLocalStorage } from "../../services/Token";

type PollProps = {
    poll: Polls | null,
    updatePoll: (poll: Polls) => void,
    isOpen: boolean,
    handleStatePoll: (isOpen: boolean) => void,
    handleHidePoll: () => void,
    userId: string,
    closePoll: boolean,
    option: number,
    activePoll: boolean,
}

type Variables = {
    userVoted: boolean,
    maxOptionValue: number[],
}

export const Poll = ({poll, updatePoll, isOpen, handleStatePoll, handleHidePoll, userId, closePoll, option, activePoll}: PollProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);

    const { handleVote, toggleWinner, determinateOptionMaxWidth, optionSelected, setOptionSelected, validateOptionSelected } = usePoll();

    const timer = 15;

    const [variables, setVariables] = useState<Variables>({
        userVoted: false,
        maxOptionValue: toggleWinner(poll),
    })
    const [percent, setPercent] = useState<number>(0);
    const intervalRef = useRef<any>();

    function handleStopInterval() {
        if (percent >= 100) clearInterval(intervalRef.current);
    }

    const startInterval = () => intervalRef.current = setInterval(() => setPercent(prev => prev + 1), (timer * 10));

    useEffect(() => {
        if (closePoll) {
            setOptionSelected(option);
        }
    }, [])

    useEffect(() => {
        if (isOpen) {
            setPercent(0);
            setTimeout(() => startInterval(), 1000)
        } else setPercent(100);

        return () => clearInterval(intervalRef.current);
	}, [isOpen]);

    useEffect(() => {
		if (percent >= 100) {
			handleStopInterval();
		}
        if (isOpen && percent === 100) {
            if (closePoll) {
                setActive(false);
                document.querySelector(".polls-container")?.classList.add('poll-open__hide');
                setTimeout(() => handleHidePoll(), 2000)
            } else handleStatePoll(false);
        }
	}, [percent]);

    const togglePoll = (id: number, order: number) => () => {
        if (loading || closePoll) return;

        setOptionSelected(order)
        setLoading(true);

        const access_token = getAccessTokenFromLocalStorage();
        handleVote({pollId: id, optionSelected: order, access_token, userId})
        .then(newPoll => {
            // restart interval
            clearInterval(intervalRef.current)
            // restart percent progressbar
            setPercent(0);
            startInterval();

            updatePoll(newPoll);
            setVariables({
                ...variables,
                maxOptionValue: toggleWinner(newPoll),
                userVoted: true,
            })
            // Storage in local storage the userId and the poll id
            const saveLocalUserVote: LocalUserVote = {
                userId,
                pollId: id,
                optionSelected: order,
            }
            Storage.set(USER_VOTES, JSON.stringify(saveLocalUserVote));
            // Define the poll `open` for watch results
            handleStatePoll(true);
            // When timer over close poll
            setTimeout(() => {
				handleStatePoll(false)
				setTimeout(() => handleHidePoll(), 2000)
			}, timer * 1000)
        })
        .catch(err => console.log(err))
    }

    const handleClosePoll = (isOpen: boolean) => () => {
        handleStatePoll(!isOpen);
    }
    const toggleValidateClosePoll = (order:number) => closePoll && variables.maxOptionValue.includes(order);

    useEffect(() => {
        setTimeout(() => determinateOptionMaxWidth(), 100)
    }, [poll])

    const toggleSelection = (order: number) => {
        if (variables.userVoted && optionSelected === order) return true;

        if (toggleValidateClosePoll(order)) return true;

        return false;
    }

    useEffect(() => {
        if (isOpen) setTimeout(() => setActive(true), 100)
        else setTimeout(() => setActive(false), 100)
    }, [isOpen])

    return (
        <div className="polls">
            <div className={`polls-container poll-open__${(active || activePoll) ? 'show' : ''} `}>
                <div
                    className={`polls-container__header poll-header__${active ? 'open' : 'closed'}`}
                    onClick={handleClosePoll(isOpen)}
                >
                    <div>
                        <img src='/icon-poll.svg' alt="Icon Poll" />
                        <div>
                            <h3>Encuesta</h3>
                            <h3>{poll?.question}</h3>
                        </div>
                    </div>
                    <div>
                        <ProgressBar progress={percent} />
                        <br />
                        <div data-name="close-poll">
                            <img src={`/icon-arrow-down-up.svg`} alt="Active-close poll" />
                        </div>
                    </div>
                </div>
                <div className={`polls-container__body polls-body__${active ? 'open' : 'close'}`}>
                    <ul id="poll-options">
                    {
                        poll?.options.sort((a, b) => a.order - b.order).map((option) => (
                            <li key={`${poll.id}__${option.order}`} className={toggleSelection(option.order) ? 'option__selected' : ''} onClick={togglePoll(poll!.id, option.order)}>
                                <div className={`${(variables.userVoted || closePoll) ? 'show' : 'hide'}__percentage`}>
                                    <div className={`progress__bar progress__bar-${toggleSelection(option.order) ? 'winner' : 'lose'}`} style={{
                                        width: `${option.percentage}%`
                                    }} />
                                </div>
                                <div className={`information ${(closePoll || variables.userVoted) ? 'information__active' : ''}`}>
                                    <div>
                                        {
                                            (!variables.userVoted && !closePoll) && (
                                                <img src={`/icon-radio-${validateOptionSelected(option.order)  ? 'on' : 'off'}.svg`} alt="Opcion seleccionada" />
                                            )
                                        }
                                        <p>{option.text}</p>
                                        {
                                            ((closePoll || variables.userVoted) && optionSelected === option.order) &&
                                            <img src="/icon-check.svg" alt="Check option." />
                                        }
                                    </div>
                                    {
                                        (closePoll || variables.userVoted) &&
                                        <span>{option.percentage}%</span>
                                    }
                                </div>
                            </li>
                        ))
                    }
                    </ul>
                </div>
            </div>
        </div>
    )
}