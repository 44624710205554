export const IconArrow = () => (
    <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Mask</title>
        <defs>
            <polygon id="path-1" points="7.41 1.41 6 0 0 6 6 12 7.41 10.59 2.83 6"></polygon>
        </defs>
        <g id="VERSION-USADA" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="laliga-livechat--respuestas-detalle" transform="translate(-49, -213)">
                <g id="ic_clear" transform="translate(49, 213)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <use id="Mask" fill="#212121" xlinkHref="#path-1"></use>
                </g>
            </g>
        </g>
    </svg>
)