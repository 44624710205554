import { useEffect } from "react";

export const OneTrust = () => {
   const isTest = process.env.ENVIRONMENT !== "production";
   const oneTrustId = `2fc6e8f7-8597-431a-8cff-843636001dad${isTest ? "-test" : ""
      }`;
   useEffect(() => {
      // Add the second script
      const script2 = document.createElement("script");
      script2.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
      script2.type = "text/javascript";
      script2.dataset.domainScript = oneTrustId;
      document.body.appendChild(script2);

      // Add the third script
      const script3 = document.createElement("script");
      script3.innerHTML = "function OptanonWrapper() {}";
      script3.type = "text/javascript";
      document.body.appendChild(script3);

      return () => {
         // Cleanup on unmount
         script2.remove();
         script3.remove();
      };
   }, [oneTrustId]);

   return <></>;
};
