import { getToken } from "../services/Token"

const useToken = () => {
    const fetchTokenByUser = async (backendApi: string, access_token: string | null) => {
        return await getToken(backendApi, access_token)
        .then(response => {
            return response?.token
        })
    }

    return {
        fetchTokenByUser
    }

}

export default useToken