import { Storage } from "./storage"


export const detectFirstTime = () => {
    const firstTime = Storage.get('first_time')

    if (firstTime) {
        if (firstTime === 'true') {
            Storage.set('first_time', 'false')
        }
        return false
    }

    Storage.set('first_time', 'false')
    return true
}