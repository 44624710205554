export const ActionPinned = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
  >
    <title>icon-pin-on</title>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-34, -207)"
        fill="#FF4B44"
        fillRule="nonzero"
      >
        <g id="icon-pin-on" transform="translate(34, 207)">
          <path
            d="M14.7990179,5.4899033 L9.46166718,0.180985823 C9.21906033,-0.0603286077 8.85515006,-0.0603286077 8.61254321,0.180985823 L6.6716884,2.11150127 C6.36842984,2.41314431 6.48973326,2.77511595 6.6716884,2.95610178 L7.09625039,3.37840203 L5.27669901,5.18826026 C4.36692332,5.00727444 1.88020309,4.58497418 0.545865414,5.91220355 C0.303258564,6.15351799 0.303258564,6.51548963 0.545865414,6.75680406 L4.00301304,10.1955347 L0.181955138,13.996237 C-0.0606517127,14.2375514 -0.0606517127,14.5995231 0.181955138,14.8408375 C0.424561989,15.0821519 0.849123978,15.0218233 1.03107912,14.8408375 L4.85213702,11.0401352 L8.30928464,14.4788658 C8.67319492,14.7805089 9.0371052,14.6598517 9.15840862,14.4788658 C10.4927463,13.1516365 10.0681843,10.6781636 9.88622917,9.77323445 L11.7057806,7.96337622 L12.1303425,8.38567647 C12.3729494,8.6269909 12.7368597,8.6269909 12.9794665,8.38567647 L14.9203213,6.45516102 C15.0416248,6.09318938 15.0416248,5.73121773 14.7990179,5.4899033 L14.7990179,5.4899033 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);
