export const t = (key: "mute" | "unmute" | "ban" | "unban", {user, date}: {user: string, date: string}) => {
    const message = locales[key];

    if (!message) {
        throw new Error(`Key ${key} not found in locales`);
    }

    return message.replace('{{user}}', user).replace('{{date}}', date);
}

export interface LocalesType {
    mute:   string;
    unmute: string;
    ban:    string;
    unban:  string;
}


const locales: LocalesType = {
    'mute': 'Usuario {{user}} muteado el {{date}}',
    'unmute': 'Usuario {{user}} desmuteado el {{date}}',
    'ban': 'Usuario {{user}} baneado el {{date}}',
    'unban': 'Usuario {{user}} desbaneado el {{date}}',
}