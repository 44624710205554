import { formatNumber } from "../../utils";

export const IconUser = ({watchers}: {watchers: number}) => (
    <div style={{ display: 'none' }}>
        <h3 data-name='watchers'>{formatNumber(watchers)}</h3>
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Mask</title>
            <defs>
                <path d="M8,8 C10.21,8 12,6.21 12,4 C12,1.79 10.21,0 8,0 C5.79,0 4,1.79 4,4 C4,6.21 5.79,8 8,8 L8,8 Z M8,10 C5.33,10 0,11.34 0,14 L0,16 L16,16 L16,14 C16,11.34 10.67,10 8,10 L8,10 Z" id="path-user"></path>
            </defs>
            <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="laliga-livechat--poll-02" transform="translate(-389, -67)">
                    <g id="Group-11" transform="translate(25, 21)">
                        <g id="Group-15" transform="translate(319, 42)">
                            <g id="ic_person" transform="translate(45, 4)">
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-user"></use>
                                </mask>
                                <use id="Mask" fill="#1F1F1F" xlinkHref="#path-user"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
)