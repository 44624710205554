import { useEffect, useState } from "react";

import Avatar from 'react-avatar'
import { Storage } from "../../services/storage";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../constants";
import { useNavigate } from "react-router-dom";


export const User = (
    {username, thumbnail, team, handleShowRules, showRules}:
    {username: string, thumbnail: string | null, team: string, handleShowRules: () => void, showRules: boolean}) => {
    const [openDrop, setOpenDrop] = useState<boolean>(false)

    const handleClick = () => setOpenDrop(!openDrop)
    const handleHover = (isVisible: boolean) => () => {
        setOpenDrop(isVisible)
    }

    useEffect(() => {
        if (showRules) setOpenDrop(false)
    }, [showRules])

    return (
        <div className={`user-information ${openDrop ? 'active-dropdown' : ''}`}>
            <UserName name={username ?? 'No User'} handleClick={handleClick} handleHover={handleHover} />
            <div className="user-information__avatar">
                <Avatar src={thumbnail ?? ''} name={thumbnail ?? 'No User'} />
            </div>
            <Dropdown handleHover={handleHover} team={team} handleShowRules={handleShowRules} />
        </div>
    )
}

const UserName = (
    { name, handleClick, handleHover }: {name: string, handleClick: () => void, handleHover: (isActive: boolean) => () => void}) => {
    return (
        <div
            className="user-information__options"
            onClick={handleClick}
            onMouseEnter={handleHover(true)}
            onMouseLeave={handleHover(false)}
        >
            <h3>{name}</h3>
            <img src="/icon-arrow-down.svg" alt="Arrow down" />
        </div>
    )
}

const Dropdown = ({handleHover, team, handleShowRules} :
    {
        handleHover: (isActive: boolean) => () => void,
        team: string,
        handleShowRules: () => void
    }) => {
    const navigate = useNavigate()

    const handleLogout = () => {
        Storage.remove(ACCESS_TOKEN)
        Storage.remove(REFRESH_TOKEN)

        navigate(`/${team}`)
    }
    return (
        <div
            className="dropdown-content"
            // onClick={handleClick}
            onMouseEnter={handleHover(true)}
            onMouseLeave={handleHover(false)}
        >
            {/* <a href="#">
                <img src="/icon-profile.svg" alt="Profile" />
                <span>Editar perfil</span>
            </a> */}
            <a href="#" onClick={handleShowRules}>
                <img src="/icon-profile.svg" alt="Profile" />
                <span>Reglas del canal</span>
            </a>
            <a href="#" onClick={handleLogout}>
                <img src="/icon-logout.svg" alt="Logout" />
                <span>Cerrar sesión</span>
            </a>
        </div>
    )
}
