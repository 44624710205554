export const TEAMS = ['real-madrid', 'barcelona', 'atletico-madrid', 'match', 'testing']

export const STORAGE_KEY = 'userId'

export const BACKEND_API = process.env.REACT_APP_API_URL || ""

export const BANNER_USER = {
    "attachments": [],
    "mentioned_users": [],
    "text": "/ban @daniel Usuario baneado"
}

export const ACCESS_TOKEN = 'access_token_la_liga'
export const REFRESH_TOKEN = 'refresh_token_la_liga'
export const USER_VOTES = 'user_vote'
export const MESSAGE_SYSTEM_TYPE = 'system'
export const ROLE_TYPES = ['admin', 'moderator']

export const DELETED = 'deleted'

export const FIRST_TIME  = 'first_time'

export const RULES: {label: string, text: string}[] = [
    { label: "Respeto", text: "Trata a los demás con cortesía, evitando cualquier mensaje o comportamiento ofensivo, discriminatorio o intimidatorio." },
    { label: "Uso de mayúsculas", text: "Evita escribir completamente en mayúsculas."},
    { label: "Sin spam", text: "No envíes mensajes no apropiados, repetitivos, publicidad no deseada, enlaces irrelevantes o ilegales."},
    { label: "Temas relevantes", text: "Mantén las conversaciones enfocadas en temas relacionados con el partido o el chat."},
    { label: "No acoso", text: "No hostigues, intimides o persigas a otros usuarios en ningún contexto.  Evita difundir información falsa, difamatoria o expresiones de odio."},
    { label: "Cumplir con las políticas", text: "Sigue las reglas establecidas por LaLiga para el uso del chat para evitar que los moderadores eliminen tus mensajes o te expulsen del canal.  Hagamos de este espacio un lugar agradable para los fans del fútbol."},
]

export const customReactions = [
    {
      colons: ':arrow_up:',
      emoticons: [],
      id: 'arrow_up',
      name: 'Upwards Black Arrow',
      native: '⬆️',
      skin: null,
      unified: '2b06-fe0f',
    },
    {
      colons: ':arrow_down:',
      emoticons: [],
      id: 'arrow_down',
      name: 'Downwards Black Arrow',
      native: '⬇️',
      skin: null,
      unified: '2b07-fe0f',
    },
  ];

export const customReactions1 = [
    {
        colons: ':soccer:',
        emoticons: [],
        id: 'soccer',
        name: 'Soccer Ball',
        native: '⚽️',
        skin: null,
        unified: '26bd',
    },
    {
        colons: ':muscle:',
        emoticons: [],
        id: 'muscle',
        name: 'Flexed Biceps',
        native: '💪',
        skin: null,
        unified: '1f4aa',
    },
    {
        colons: ':heart:',
        emoticons: [],
        id: 'heart',
        name: 'Red Heart',
        native: '❤️',
        skin: null,
        unified: '2764',
    },
    {
        colons: ':wink:',
        emoticons: [],
        id: 'wink',
        name: 'Winking Face',
        native: '😉',
        skin: null,
        unified: '1f609',
      },
    {
        colons: ':raised_hands:',
        emoticons: [],
        id: 'raised_hands',
        name: 'Person Raising Both Hands in Celebration',
        native: '👐',
        skin: null,
        unified: '1f64c',
    },
    {
        colons: ':pensive:',
        emoticons: [],
        id: 'pensive',
        name: 'Sad Pensive Face',
        native: '😔',
        skin: null,
        unified: '1f614',
    },
    {
        colons: ':joy:',
        emoticons: [],
        id: 'joy',
        name: 'Face with Tears of Joy',
        native: '😂',
        skin: null,
        unified: '1f602',
    },
    {
        colons: ':trophy:',
        emoticons: [],
        id: 'trophy',
        name: 'Trophy',
        native: '🏆',
        skin: null,
        unified: '1f3c6',
    },
];