export const LogoLiveChat = () => (
    <svg width="70px" height="22px" viewBox="0 0 70 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Artboard</title>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-5">
            <text id="Chat" fontFamily="WorkSans-Bold, Work Sans" fontSize="13.9897436" fontWeight="bold" letterSpacing="0.0466324779" fill="#242424">
                <tspan x="31.8999498" y="17.7930034">CHAT</tspan>
            </text>
            <g id="chat" fill="#FF4B44" fillRule="nonzero">
                <path d="M21.9551853,6.26799482 C25.2476563,9.24153155 25.7851082,14.2376609 23.2014078,17.8528236 C23.1498258,17.917833 23.1267219,18.0011908 23.1374113,18.0837192 C23.1481007,18.1662477 23.1916631,18.2408407 23.2580763,18.2903371 C23.2816646,18.3046198 25.2311632,19.7788667 25.844799,20.2401546 C26.2464977,20.3938511 26.4850502,20.8114517 26.4151538,21.2385955 C26.3452575,21.6657393 25.9862762,21.9840913 25.5568659,21.9998728 L14.8889432,21.9998728 C12.3405097,22.0125197 9.87620472,21.0817021 7.96423085,19.3841397 L11.6413515,19.3841397 C15.1070845,19.4054312 18.3819877,17.7874062 20.4872422,15.0136678 C22.3801916,12.5216139 22.9295668,9.24856085 21.9551853,6.26799482 Z M1.57758681,9.2022961 C1.92787325,-1.67983522 17.9060534,-3.45340213 21.1433511,6.81013602 C22.8712941,12.7406797 17.9560984,18.4963985 11.5375158,18.433003 L0.869607212,18.4330316 C-0.140953824,18.3768796 -0.312950361,17.1543658 0.576918717,16.6781582 L3.16366493,14.7283264 C3.23055927,14.6806503 3.27536787,14.6076485 3.28782024,14.5260535 C3.3002726,14.4444585 3.27930097,14.3612669 3.2297122,14.2955469 C2.16181699,12.8147005 1.583783,11.0327098 1.57758681,9.2022961 Z M13.732446,10.9428154 L7.8651222,10.9428154 C7.60776679,10.9475153 7.40156787,11.1590418 7.40156787,11.4183674 C7.40156787,11.677693 7.60773409,11.8892341 7.86508918,11.8939521 L13.732446,11.8939521 C13.9903521,11.8900125 14.1973761,11.6782515 14.1973761,11.4183838 C14.1973761,11.158516 13.9903521,10.9467551 13.732446,10.9428154 Z M15.2004882,6.51052604 L7.8651222,6.51052604 C7.6077172,6.51515623 7.40144403,6.72670369 7.40144403,6.98607799 C7.40144403,7.2454523 7.60768917,7.45701222 7.86509389,7.46165796 L15.2004882,7.46165796 C15.4584542,7.45780402 15.6655709,7.24601883 15.6655709,6.986092 C15.6655709,6.72616517 15.4584542,6.51437998 15.2004882,6.51052604 Z" id="Combined-Shape"></path>
            </g>
        </g>
    </g>
</svg>
)