import { Channel as ChannelT } from "stream-chat"
import { useEffect } from "react"

import { SimboloLaLiga } from "../../assets/icons/SimboloLaLiga"
import { LogoLiveChat } from "../../assets/icons/LogoLiveChat"
import { User } from "./User"
import { StreamChatType, UserInformationType } from "../../types"
import { IconUser } from "../../assets/icons/IconUsers"
import { useChannelWatch } from "../../hooks/useChannelWatch"

export const ChatHeader = ({
    userInformation, team, channel, handleShowRules, showRules
    }:{
        userInformation: UserInformationType, team: string, channel: ChannelT<StreamChatType>, handleShowRules: () => void, showRules: boolean
    }) => {
    const { watchers } = useChannelWatch(channel);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (channel) {
                channel.stopWatching();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [channel]);


    return (
        <header>
            <div>
                <SimboloLaLiga />
                <IconUser watchers={watchers} />
            </div>
            <div>
                <LogoLiveChat />
                <User
                    username={userInformation.username}
                    thumbnail={userInformation.thumbnail}
                    team={team}
                    handleShowRules={handleShowRules}
                    showRules={showRules}
                />
            </div>
        </header>
    )
}