import { useEffect, useState } from 'react';
import {
  ChatAutoComplete,
  EmojiPicker,
  useChannelStateContext,
  useMessageInputContext,
  useTypingContext,
} from 'stream-chat-react';

import EmojiIcon from '../../assets/icons/EmojiIcon';
import { SendIcon } from '../../assets/icons/SendIcon';

import type {StreamChatType} from '../../types';

const TypingIndicator = () => (
    <div className='typing-indicator'>
      <div className='dots'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
      </div>
      <p>a member is typing</p>
    </div>
)

export const GamingMessageInput = ({maxLength, stayFocus}: {maxLength: number, stayFocus: boolean}) => {
  const { thread } = useChannelStateContext<StreamChatType>();
  const { typing } = useTypingContext<StreamChatType>();
  const messageInput = useMessageInputContext<StreamChatType>();
  const [activeEmojis, setActiveEmojis] = useState<boolean>(false);
  const maxLengthLocal = maxLength + 1

  const [showMessage, setShowMessage] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const value = event.target.value;

    if (value.length > maxLengthLocal) setShowMessage(true);
    else setShowMessage(false);

    if (value.length <= maxLengthLocal) {
      messageInput.setText(value);
    }
  }
  const handleDisabled = () => {
    return !messageInput.text || messageInput.text.length >= maxLengthLocal;
  }

  const handleEmojiPicker = (event: any) => {
    messageInput.openEmojiPicker(event);
    setTimeout(() => setActiveEmojis(!activeEmojis), 10)
  }

  useEffect(() => {
    if (messageInput.text.length > 0) {
      const end = messageInput.text.length;

      // ✅ Move focus to END of input field
      messageInput.textareaRef.current!.setSelectionRange(end, end);
      messageInput.textareaRef.current?.focus();
    }

  }, []);

  useEffect(() => {
    if (stayFocus) {
      messageInput.textareaRef.current?.focus();
    }
  }, [stayFocus]);

  return (
    <div className='channel-footer'>
      {!thread && (
        <EmojiPicker/>
        )}
      <div className='channel-footer__top'>
        <ChatAutoComplete rows={1} placeholder='Escribe algo...' onChange={handleChange} value={messageInput.text} />
        {!thread && <button className='emoji-button' onClick={handleEmojiPicker}><EmojiIcon /></button>}
      </div>
      <div className='channel-footer__bottom'>
        {typing && !!Object.keys(typing).length && (
          <TypingIndicator/>
          )}
        <button className='send-message-button' disabled={handleDisabled()} onClick={messageInput.handleSubmit}>
          <SendIcon />
        </button>
      </div>
      <span className={`message-length message-length__${showMessage ? 'active' : 'disabled'}`}>El mensaje es demasiado largo</span>
    </div>
  );
};
