type ProgressBarProps = {
    progress: number | undefined;
};

export function ProgressBar({ progress = 0 }: ProgressBarProps) {
  return (
    <div className="progress-bar">
      <div className={`progress-bar__inner ${progress > 0 ? 'progress-bar__active' : ''}`} style={{ width: `${progress}%` }} />
    </div>
  );
}