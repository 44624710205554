import React from 'react';
import { Channel as ChannelT, StreamChat } from 'stream-chat';
import { Thread } from 'stream-chat-react';

import { GamingMessage } from './GamingMessage';
import { GamingMessageInput } from './GamingMessageInput';
import { PopupType, StreamChatType, Team } from '../../types';
import { GamingChatHeader } from './GamingChatHeader';

type GamingThreadProps = {
  client: StreamChat<StreamChatType>;
  popupSetting: PopupType;
  handlePopupSettingd: ({type, isOpen, messageId}: PopupType) => void;
  channel: ChannelT<StreamChatType>;
  stayFocus: boolean;
  teams: Team[];
  isOpen: boolean;
}

export const GamingThread = ({client, channel, popupSetting, handlePopupSettingd, stayFocus, teams, isOpen}: GamingThreadProps) => {
  const GamingMessageClient = () =>
    <GamingMessage client={client} popupSetting={popupSetting} handlePopupSettingd={handlePopupSettingd} />

  const GamingMessageInputClient = () =>
    <GamingMessageInput maxLength={channel.getConfig()?.max_message_length ?? 100} stayFocus={stayFocus} />

  return <>
    { isOpen && <GamingChatHeader teams={teams} /> }
    <Thread Message={GamingMessageClient} Input={GamingMessageInputClient} />
  </>
};
