import {useCallback, useEffect, useState} from 'react';
import {logChatPromiseExecution, StreamChat} from 'stream-chat';
import {Channel as ChannelT} from 'stream-chat';
import {MessageInput, MessageList, MessageToSend, StreamMessage, useChannelActionContext, useChannelStateContext, useMessageInputContext, Window} from 'stream-chat-react';

import {GamingChatHeader} from './GamingChatHeader';
import {GamingMessage} from './GamingMessage';
import {GamingThread} from './GamingThread';

import {useLayoutController} from '../../context/LayoutController';

import type {LocalUserVote, Polls, PopupType, StreamChatType} from '../../types';
import { useInfoDay } from '../../hooks/useInfoDay';
import { GameInformation } from '../GameInformation';
import { SimboloLaLiga } from '../../assets/icons/SimboloLaLiga';
import { PopupConfirmation } from './Popup';
import { Poll } from '../Poll/Index';
import { sortByPinnedDate } from '../../utils';
import { useChannelWatch } from '../../hooks/useChannelWatch';
import { Storage } from '../../services/storage';
import { USER_VOTES } from '../../constants';
import { getAccessTokenFromLocalStorage } from '../../services/Token';
import { usePoll } from '../../hooks/usePoll';
import { isAction, updateMuteMessage } from '../../services/Message';

const RULES = '/rules';

const NOTIFICATION_TEXT_FOR_COMMAND: Record<string, string> = {
  '/ban': 'Usuario baneado',
  '/mute': 'Usuario muteado',
  '/unban': 'Usuario desbaneado',
  '/unmute': 'Usuario desmuteado',
};

const getNotificationText = (messageText?: string): string | null => {
  for (const command of Object.keys(NOTIFICATION_TEXT_FOR_COMMAND)) {
    if (messageText?.startsWith(command))
      return NOTIFICATION_TEXT_FOR_COMMAND[command];
  }
  return null;
}

type GamingChatInnerProps = {
  channelId: string;
  channel: ChannelT<StreamChatType>;
  client: StreamChat<StreamChatType>;
  team: string | undefined;
  handleShowRules: () => void;
};

export const GamingChatInner = ({channelId, team, client, channel, handleShowRules}: GamingChatInnerProps) => {
  const [popupSetting, setPopupSetting] = useState<PopupType>({
    isOpen: false,
    type: 'remove',
    messageId: '',
    userId: '',
  });

  const { getActivePoll } = usePoll();
  const { activePoll, setActivePoll, isOpen, setIsOpen, poll, setPoll, closePoll, activeShowPoll, stayFocus } = useChannelWatch(channel);
  const { sendMessage } = useChannelActionContext();
  const { publishAppNotification } = useLayoutController();
  const { thread } = useChannelStateContext();

  const toggleThreadOpen = () => thread !== null

  const { teams, date, filterByAttribute, handleContentByDate } = useInfoDay(team)

  const overrideSubmitHandler = useCallback((message: MessageToSend<StreamChatType>) => {
    const { text } = message;

    if (!text?.includes(RULES)) {
      publishAppNotification(getNotificationText(text))

      if (
        (
          isAction('mute', message.text) ||
          isAction('unmute', message.text) ||
          isAction('unban', message.text)
        ) &&
        message.mentioned_users
      ) {
        const newMessage = updateMuteMessage(message.text, message.mentioned_users[0].id, '@');
        message.text = newMessage;
      }

      const sendMessagePromise = sendMessage(message);
      logChatPromiseExecution(sendMessagePromise, 'send message');
    } else {
      handleShowRules();
    }
  }, [publishAppNotification, sendMessage]);

  const togglePopup = (isOpen: boolean) => setPopupSetting({...popupSetting, isOpen});

  const GamingMessageClient = () =>
    <GamingMessage client={client} popupSetting={popupSetting} handlePopupSettingd={setPopupSetting} />

    let optionId = -1;
    let pollId: number | null = null;
    const userVote = Storage.get(USER_VOTES);
    if (userVote) {
      if (userVote.includes('__')) Storage.remove(USER_VOTES);

      const localUserVote = JSON.parse(userVote) as LocalUserVote;
      pollId = localUserVote.pollId ?? 0;
      optionId = localUserVote.optionSelected;
    }
    const access_token = getAccessTokenFromLocalStorage();

  useEffect(() => {
    getActivePoll({channelId: `livestream:${channelId}`, access_token})
    .then(poll => {
      if (poll && (pollId !== poll?.id)) {
        activeShowPoll(poll);
      }
    })
  }, []);

  const gamingThread = useCallback(() => {
    return (
      <GamingThread
        client={client}
        channel={channel}
        popupSetting={popupSetting}
        handlePopupSettingd={setPopupSetting}
        stayFocus={true}
        teams={teams}
        isOpen={toggleThreadOpen()}
      />
    )
  }, [client, channel, popupSetting, teams, toggleThreadOpen]);

  const filterMessages = (messages: StreamMessage<StreamChatType>[], pinned: boolean) => {
    const m = messages.filter(message => message.pinned === pinned && !message.silent)
    if (m.length > 0) return m

    return []
  }

  const removeMutedMessage = (messages: StreamMessage<StreamChatType>[]) => {
    return filterMessages(messages, false)
    .filter(message => {
      if (client.mutedUsers.some(muted => muted.target.id === message.user?.id)) return false
      return true
    })
  }

  return (
    <>
      <Window>
        {
          handleContentByDate(date)
          ? <div className='custom-game-information'>
              <SimboloLaLiga />
              <p>No pierda la oportunidad de comentar nuestro próximo partido.</p>
              <GameInformation
                teams={teams}
                date={date}
                filterByAttribute={filterByAttribute}
                activeText
              />
            </div>
          : <>
              <GamingChatHeader teams={teams} />
              <div className={`game-chat-body ${(channel.state.pinnedMessages.length > 0 || activePoll) ? 'game-chat-body__pinned' : ''}`}>
                {
                  channel.state.pinnedMessages.length > 0 &&
                  <GamingMessage
                    client={client}
                    popupSetting={popupSetting}
                    handlePopupSettingd={setPopupSetting}
                    messagePinned={sortByPinnedDate(channel.state.pinnedMessages)[0]}
                  />
                }
                {
                  activePoll &&
                  <Poll
                    poll={poll}
                    updatePoll={(poll: Polls) => setPoll(poll)}
                    isOpen={isOpen}
                    handleStatePoll={(isOpen: boolean) => setIsOpen(isOpen)}
                    handleHidePoll={() => setActivePoll(false)}
                    userId={client.userID ?? ''}
                    closePoll={closePoll}
                    option={optionId}
                    activePoll={activePoll}
                  />
                }
                <MessageList
                  messages={removeMutedMessage(channel.state.messages)}
                  Message={GamingMessageClient}
                />
                {
                  popupSetting.isOpen &&
                  <PopupConfirmation
                    type={popupSetting.type}
                    tooglePopup={togglePopup}
                    messageId={popupSetting.messageId}
                    client={client}
                    channel={channel}
                    userId={popupSetting.userId}
                  />
                }
              </div>
              <MessageInput
                focus
                grow
                overrideSubmitHandler={overrideSubmitHandler}
                additionalTextareaProps={{
                  maxLength: channel.getConfig()?.max_message_length,
                }}
                disableMentions={false}
              />
            </>
        }
      </Window>
      { gamingThread() }
    </>
  );
};
