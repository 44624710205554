import axios from "axios"
import { STORAGE_KEY } from "../constants"
import { Storage } from "./storage"
import { DataUpdateUser, ProfileInfoType } from "../types"

const userId = process.env.REACT_APP_USER_ID

export const getUserLaLiga = async (uri: string) => {
    await axios.get(uri)
    .then(() => {
        Storage.set(STORAGE_KEY, userId ?? '')
    })
    .catch(err => console.log(err))
}

export const openOathLaLiga = async (uri: string) => {
    return await axios.get(uri)
    .then(res => {
        return res.data
    })
    .catch(err => console.log(err))
}

export const getProfileInfo = async (uri: string, accessToken: string): Promise<ProfileInfoType> => {
    return await axios.get(uri, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
    .then(res => res.data)
}

export const updateProfileUser = async (uri: string, data: DataUpdateUser) => {
    return await axios.post(uri, data)
    .then(res => res.data)

}