import {Fragment, MouseEventHandler, useCallback, useEffect, useId, useMemo, useState} from 'react';
import {Attachment, MessageContextValue, useMessageContext, ReactionSelector, ReactionSelectorProps, ReactionsList, useChannelStateContext, useChannelActionContext} from 'stream-chat-react';
import {StreamChat, UserResponse} from 'stream-chat';

import {UserIcon} from './UserIcon';
import {getColor} from '../../assets/data';

import {TimestampContextValue, useMessageTimestamp} from "../../context/MessageTimestampController";

import type {StreamMessage} from 'stream-chat-react/dist/context/ChannelStateContext';
import type {PopupType, StreamChatType} from '../../types';
import { ActionRemove } from '../../assets/icons/ActionRemove';
import { ActionBanned } from '../../assets/icons/ActionBanned';
import { useLayoutController } from '../../context/LayoutController';
import { DELETED, MESSAGE_SYSTEM_TYPE, customReactions, customReactions1 } from '../../constants';
import { ActionPinned } from '../../assets/icons/ActionPinned';
import ActionThread from '../../assets/icons/ActionThread';

type Reaction = {
	type: string;
	icon: string;
	label: string;
};

const reactions: Reaction[] = [
	{ type: 'like', icon: 'thumbsup', label: 'Like' },
	{ type: 'love', icon: 'heart', label: 'Love' },
	{ type: 'haha', icon: 'laugh', label: 'Haha' },
	{ type: 'wow', icon: 'surprised', label: 'Wow' },
	{ type: 'sad', icon: 'sad', label: 'Sad' },
	{ type: 'angry', icon: 'angry', label: 'Angry' },
  ];


const getTimeStamp = (messageCreatedAt?: StreamMessage<StreamChatType>['created_at']) => {
	if (!messageCreatedAt) return '';

	const createdAt = new Date(messageCreatedAt);
	let lastHours: number | string = createdAt.getHours();
	let lastMinutes: number | string = createdAt.getMinutes();
	let half = 'AM';

	if (lastHours === undefined || lastMinutes === undefined) {
		return '';
	}

	if (lastHours > 12) {
		lastHours = lastHours - 12;
		half = 'PM';
	}

	if (lastHours === 0) lastHours = 12;
	if (lastHours === 12) half = 'PM';

	if (lastMinutes.toString().length === 1) {
		lastMinutes = `0${lastMinutes}`;
	}

	return `${lastHours}:${lastMinutes} ${half}`;
};

const getReplyButtonText = (reply_count?: number) => {
	if (!reply_count) return '';

	return <><img src="/icon-respuestas.svg" alt="Respuestas." /> <span>{reply_count} { handleTextByReply(reply_count) }</span></>
};

const handleTextByReply = (reply_count: number) => reply_count === 1 ? 'respuesta' : 'respuestas';

type MessageActionsProps = {
	handlePinnedMessage: MouseEventHandler<HTMLButtonElement>;
	handleDeleteMessage: MouseEventHandler<HTMLButtonElement>;
	handleBannedUser: MouseEventHandler<HTMLElement>;
	onOpenThread: MouseEventHandler<HTMLElement>;
	blockBanner: boolean;
	ReactionSelectorLocal: (props: CustomReactionSelectorProps) => JSX.Element;
	moderador: boolean;
	show: boolean;
	activeReactions: boolean;
}

const MessageActions = ({
	handlePinnedMessage,
	handleDeleteMessage,
	handleBannedUser,
	onOpenThread,
	blockBanner,
	ReactionSelectorLocal,
	moderador,
	show,
	activeReactions
}: MessageActionsProps) => (
	<div className='custom-message__actions-wrapper'>
		{
			show &&
			<>
				{
					activeReactions &&
					<ReactionSelectorLocal reactions={reactions} />
				}
				{
					moderador &&
					<>
						<button onClick={handlePinnedMessage}><ActionPinned/></button>
						<button onClick={handleDeleteMessage}><ActionRemove/></button>
						<button disabled={blockBanner} onClick={handleBannedUser}><ActionBanned /></button>
					</>
				}
				<button onClick={onOpenThread}><ActionThread/></button>
			</>
		}
	</div>
);

type MessageContentProps = {color?: string}
	& Pick<MessageContextValue<StreamChatType>, 'message' | 'handleAction'>
	& Pick<TimestampContextValue, 'timestampEnabled'>;

interface UserResponseWithImage extends UserResponse {
	image?: string;
}

const MessageContent = ({message, handleAction, timestampEnabled}: MessageContentProps) => {
	const user = message.user as UserResponseWithImage;

	return (
		<>
			<div className='custom-message__content'>
				<p className='message-owner'>
					<span>
						{
							user.image
							? <img src={user.image}	alt={user.name} style={{ width: '100%' }} />
							: <UserIcon type={user?.role}/>
						}
					</span>
					{
						timestampEnabled && <span className='timestamp'>{getTimeStamp(message.created_at)}</span>
					}
					<span style={{ marginBottom: 10}}><strong>{message.user?.name || message.user?.id || 'Unknown'}</strong></span>
					{ user?.role === 'moderator' ? <ModeratorTag /> : '' }
					<span className='message'>{message.text}</span>
				</p>
			</div>
			{!!message?.attachments?.length && (
				<Attachment attachments={message.attachments} actionHandler={handleAction}/>
			)}
		</>
	)
}

type GamingMessageProps = {
	client: StreamChat<StreamChatType>;
	popupSetting: PopupType;
	handlePopupSettingd: ({type, isOpen, messageId}: PopupType) => void;
	messagePinned?: StreamMessage<StreamChatType>;
}

interface CustomReactionSelectorProps extends ReactionSelectorProps {
	reactions: Reaction[];
}

export const GamingMessage = ({client, popupSetting, handlePopupSettingd, messagePinned}: GamingMessageProps) => {
	const messageId = useId();
	const {moderator} = useLayoutController();
	const {handleAction, message} = useMessageContext<StreamChatType>();
	const { channel } = useChannelStateContext<StreamChatType>();
	const {openThread} = useChannelActionContext<StreamChatType>();

	const localMessage = messagePinned ?? message;
	const {timestampEnabled} = useMessageTimestamp();
	const [showOptions, setShowOptions] = useState(false);

	const onOpenThread: MouseEventHandler<HTMLElement> = useCallback((event) => {
		const chatPanel = document.querySelector<HTMLDivElement>('.str-chat__main-panel');
		if (chatPanel) chatPanel.style.display = 'none';
		openThread(localMessage, event);
	}, [openThread, localMessage]);

	const handleDeleteMessage: MouseEventHandler<HTMLButtonElement> = async() => {
		handlePopupSettingd({
			isOpen: true,
			type: 'remove',
			messageId: localMessage.id,
			userId: localMessage.user_id ?? '',
		});
	};

	const handlePinnedMessage: MouseEventHandler<HTMLButtonElement> = async () => {
		if (localMessage.pinned) {
			await client.unpinMessage(localMessage);
		} else {
			await client.pinMessage(localMessage);
		}
	};

	const handleBannedUser = () => {
		const messageId = localMessage.user?.id ?? '';
		if (popupSetting.type === 'ban') {
			handlePopupSettingd({
				isOpen: true,
				type: 'unban',
				messageId: messageId,
				userId: localMessage.user_id ?? '',
			});
		} else {
			handlePopupSettingd({
				isOpen: true,
				type: 'ban',
				messageId: messageId,
				userId: localMessage.user_id ?? '',
			});
		}
	}

	const color = useMemo(() => {
		return localMessage.user?.color || getColor();
	}, [localMessage?.id]); // eslint-disable-line

	useEffect(() => {
		if (localMessage.type === MESSAGE_SYSTEM_TYPE && !moderator) {
			const parentNode = document.getElementById(messageId)?.parentNode as HTMLDivElement;
			parentNode.style.display = 'none';
		}
	}, [localMessage]);

	function ReactionSelectorLocal(props: CustomReactionSelectorProps) {
		return (
			<div>
				<ReactionSelector reactionOptions={customReactions1} {...props} />
			</div>
		);
	}

	const handleShowOption = (isShow: boolean) => () => !messagePinned && setShowOptions(isShow);

	return true &&
	<Fragment>
		<div
			className={`custom-message__wrapper ${localMessage.pinned ? 'custom-message__wrapper--pinned' : ''}`}
			id={messageId}
			onMouseEnter={handleShowOption(true)}
			onMouseLeave={handleShowOption(false)}
		>
			{
				localMessage.pinned &&
				<button onClick={handlePinnedMessage}><ActionPinned /></button>
			}
			<div>
				<MessageContent
					color={color}
					message={localMessage}
					handleAction={handleAction}
					timestampEnabled={timestampEnabled}
				/>
				{
					(showOptions && !messagePinned) &&
					<MessageActions
						handlePinnedMessage={handlePinnedMessage}
						handleDeleteMessage={handleDeleteMessage}
						handleBannedUser={handleBannedUser}
						onOpenThread={onOpenThread}
						blockBanner={client.userID === localMessage.user?.id}
						ReactionSelectorLocal={ReactionSelectorLocal}
						moderador={moderator}
						show={(!localMessage.pinned && localMessage.type !== DELETED)}
						activeReactions={(channel?.data?.config as any).reactions ?? false}
					/>
				}
			</div>
			<div>
				{
					(Object.keys(localMessage.reaction_counts || {}).length > 0 && !messagePinned)
					?	<>
							<ReactionsList
								reactions={localMessage?.latest_reactions || []}
								reaction_counts={localMessage?.reaction_counts || {}}
								reactionOptions={customReactions1}
							/>
						</>
					: ''
				}
				{
					(localMessage.reply_count && localMessage.reply_count > 0) ?
					<button className='reply-count' onClick={onOpenThread}>
						{getReplyButtonText(localMessage.reply_count)}
					</button> : ''
				}
			</div>
		</div>
	</Fragment>
};

const ModeratorTag = () => <span className='moderator__tag'>Moderador</span>